import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/he';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { HDate } from '@hebcal/core';  // הוספנו ייבוא זה
import { 
  getHolidaysForDateRange, 
  formatIsraeliDate,
  formatFullHebrewDate,
  formatCellDate,
  getHebrewMonthName
} from '../utils/hebrewCalendarUtils';
import PostDetailsView from './PostDetailsView';
import AddPostModal from './AddPostModal';
import { 
  AIRTABLE_BASE_ID, 
  AIRTABLE_GANTTS_TABLE, 
  AIRTABLE_POSTS_TABLE,
  getAirtableApiKey
} from '../airtableConfig';
import { createPost } from '../services/postService';
import './calendar-styles.css';
import '../styles/modal.css';

// Set moment locale to Hebrew
moment.locale('he');

const localizer = momentLocalizer(moment);

// EventComponent definition
const EventComponent = ({ event }) => {
  return (
    <div className={`calendar-event ${event.type}`}>
      <div className="event-title">{event.title}</div>
      {event.type === 'holiday' && (
        <div className="event-subtitle">{event.titleEn}</div>
      )}
    </div>
  );
};

// Custom DateCell component
const DateCellWrapper = ({ children, value }) => {
  const { hebrew } = formatCellDate(value);
  
  return (
    <div className="rbc-day-bg">
      <div className="date-display">
        <span className="gregorian-date">{value.getDate()}</span>
        <span className="hebrew-date">{hebrew}</span>
      </div>
      {children}
    </div>
  );
};

const calendarMessages = {
  week: 'שבוע',
  work_week: 'שבוע עבודה',
  day: 'יום',
  month: 'חודש',
  previous: 'הקודם',
  next: 'הבא',
  today: 'היום',
  agenda: 'סדר יום',
  showMore: total => `+${total} נוספים`,
  noEventsInRange: 'אין אירועים בטווח זה'
};

const formats = {
  dayFormat: (date) => {
    return formatIsraeliDate(date);
  },
  
  dayHeaderFormat: (date, culture, localizer) => {
    const hebrewDate = formatIsraeliDate(date);
    const gregorianDate = localizer.format(date, 'dddd DD/MM', culture);
    return `${gregorianDate}\n${hebrewDate}`;
  },
    
  monthHeaderFormat: (date, culture, localizer) => {
    const hebrewDate = formatFullHebrewDate(date); // Use full date format for header
    const gregorianDate = localizer.format(date, 'MMMM YYYY', culture);
    return `${gregorianDate} - ${hebrewDate}`;
  }
};

const SharedGanttView = () => {
  const { encodedParams } = useParams();
  const [ganttData, setGanttData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [userName, setUserName] = useState('');
  const [showNamePrompt, setShowNamePrompt] = useState(true);
  const [isAddPostModalOpen, setIsAddPostModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [view, setView] = useState(Views.MONTH);

  useEffect(() => {
    const start = moment(calendarDate).startOf(view).toDate();
    const end = moment(calendarDate).endOf(view).toDate();
    const holidaysInRange = getHolidaysForDateRange(start, end);
    setHolidays(holidaysInRange);
  }, [calendarDate, view]);

  const handleRangeChange = (range) => {
    const { start, end } = range;
    const holidaysInRange = getHolidaysForDateRange(start, end);
    setHolidays(holidaysInRange);
  };

  // Combine posts and holidays
  const events = [...(posts.map(post => ({
    id: post.id,
    title: post.title || 'Untitled Post',
    start: new Date(post.scheduledDate),
    end: new Date(post.scheduledDate),
    type: 'post'
  }))), ...holidays];

  // Event style getter
  const eventStyleGetter = (event) => {
    if (event.type === 'holiday') {
      return {
        style: {
          backgroundColor: '#FFE4E1',
          color: '#FF4500',
          border: '1px solid #FF4500'
        }
      };
    }
    return {
      style: {
        backgroundColor: '#3174ad'
      }
    };
  };  

  const fetchFromAirtable = async (endpoint) => {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${endpoint}`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch from Airtable');
    }
    return response.json();
  };

  const fetchPostDetails = async (postIds) => {
    const fetchedPosts = [];
    for (const postId of postIds) {
      const data = await fetchFromAirtable(`${AIRTABLE_POSTS_TABLE}/${postId}`);
      fetchedPosts.push({
        id: data.id,
        title: data.fields.Title,
        description: data.fields.Description,
        scheduledDate: data.fields['Scheduled Date'],
        status: data.fields.Status,
        ganttChart: data.fields['Gantt Chart'] ? data.fields['Gantt Chart'][0] : null,
        client: data.fields.Client ? data.fields.Client[0] : null,
        images: data.fields.Images,
        notes: data.fields.Notes,
        platform: data.fields.Platform
      });
    }
    return fetchedPosts;
  };

  // Remove the filterPosts function and its usage
const loadGanttData = async () => {
  setIsLoading(true);
  setError(null);
  try {
    let decodedParams = JSON.parse(atob(encodedParams));
    const { gantt: ganttId, expires } = decodedParams;

    if (!ganttId) {
      throw new Error('Invalid Gantt chart ID');
    }

    if (expires && new Date(expires) < new Date()) {
      throw new Error('This share link has expired');
    }

    const data = await fetchFromAirtable(`${AIRTABLE_GANTTS_TABLE}/${ganttId}`);
    if (!data || !data.fields) {
      throw new Error('Failed to load Gantt chart data');
    }
    setGanttData(data);
    
    if (data.fields.Posts && data.fields.Posts.length > 0) {
      const fetchedPosts = await fetchPostDetails(data.fields.Posts);
      setPosts(fetchedPosts); // No filtering
    } else {
      console.log('No posts found for this Gantt chart');
    }
  } catch (error) {
    console.error('Failed to load Gantt data:', error);
    setError(error.message || 'Failed to load data. Please try again.');
  } finally {
    setIsLoading(false);
  }
};

const getHebrewMonthTitle = (date) => {
  try {
    return `נובמבר 2024 - ${getHebrewMonthName(date)} תשפ"ה`;
  } catch (error) {
    console.error('Error getting Hebrew month:', error);
    return 'נובמבר 2024';
  }
};

  useEffect(() => {
    if (!showNamePrompt) {
      loadGanttData();
    }
  }, [showNamePrompt, encodedParams]);

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    
    if (userName.trim()) {
      try {
        // Decode the shared link parameters
        let decodedParams = JSON.parse(atob(encodedParams));
        
        // Update Gantt chart with approver information
        await updateGanttInAirtable(decodedParams.gantt, {
          date_approving: new Date().toISOString(),
          'The name of the approving authority': userName.trim()
        });
        
        // Hide the name prompt and show the calendar
        setShowNamePrompt(false);
        
        // Load the Gantt data
        await loadGanttData();
      } catch (error) {
        console.error('Error updating Gantt data:', error);
        setError('Failed to save approver information. Please try again.');
      }
    }
  };

  const updateGanttInAirtable = async (ganttId, updates) => {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_GANTTS_TABLE}/${ganttId}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fields: updates })
    });

    if (!response.ok) {
      throw new Error('Failed to update Gantt chart');
    }

    return response.json();
  };

  const handleSelectEvent = (event) => {
    const postIndex = posts.findIndex(p => p.id === event.id);
    setCurrentPostIndex(postIndex);
    setSelectedPost(posts[postIndex]);
  };

  const handlePostAction = async (postId, notes, status) => {
    try {
      await updatePostInAirtable(postId, { Status: status, Notes: notes });
      setPosts(prevPosts => prevPosts.map(post => 
        post.id === postId ? { ...post, status, notes } : post
      ));
      handleNavigate('next');
    } catch (error) {
      console.error('Error updating post:', error);
      setError(`Failed to ${status === 'נדחה' ? 'reject' : 'approve'} post. Please try again.`);
    }
  };

  const handleApprovePost = (postId, notes, status) => handlePostAction(postId, notes, status);
  const handleRejectPost = (postId, notes) => handlePostAction(postId, notes, 'נדחה');

  const updatePostInAirtable = async (postId, updates) => {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_POSTS_TABLE}/${postId}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fields: updates })
    });

    if (!response.ok) {
      throw new Error('Failed to update post');
    }

    const data = await response.json();
    return data.fields;
  };

  const handleNavigate = (direction) => {
    const newIndex = direction === 'next' ? currentPostIndex + 1 : currentPostIndex - 1;
    if (newIndex >= 0 && newIndex < posts.length) {
      setCurrentPostIndex(newIndex);
      setSelectedPost(posts[newIndex]);
    } else if (newIndex >= posts.length) {
      setSelectedPost(null);
    }
  };

  const handleClosePostDetails = () => {
    setSelectedPost(null);
    setCurrentPostIndex(0);
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    setIsAddPostModalOpen(true);
  };

  const handleAddPost = async (newPost) => {
    try {
      let decodedParams = JSON.parse(atob(encodedParams));
      console.log('Creating post with params:', {
        ...newPost,
        clientId: decodedParams.client,
        ganttChartId: decodedParams.gantt
      }); // Debug log
  
      const createdPost = await createPost({
        ...newPost,
        clientId: decodedParams.client,
        ganttChartId: decodedParams.gantt
      });
  
      setPosts(prevPosts => [...prevPosts, createdPost]);
      setIsAddPostModalOpen(false);
    } catch (error) {
      console.error('Error creating post:', error);
      alert(`שגיאה ביצירת הפוסט: ${error.message}`);
    }
  };

  if (showNamePrompt) {
    return (
      <div className="name-prompt-container">
        <div className="name-prompt-box">
          <h2>ברוך הבא!</h2>
          <p>לפני שנמשיך, אנא הזן את שמך:</p>
          <form onSubmit={handleNameSubmit}>
            <input 
              type="text" 
              value={userName} 
              onChange={(e) => setUserName(e.target.value)} 
              placeholder="הכנס את שמך"
              className="name-input"
              required
            />
            <button type="submit" className="submit-button">
              המשך
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (isLoading) return <div>טוען נתונים...</div>;
  if (error) return <div>שגיאה: {error}</div>;
  if (!ganttData) return <div>לא נמצאו נתונים עבור הגאנט המבוקש.</div>;

  return (
    <div className="calendar-container" style={{ direction: 'rtl' }}>
    {/* עדכון הכותרת */}
    <div className="calendar-header">
      <h2 className="calendar-title">
        {ganttData?.fields ? 
          `${ganttData.fields.Name}` : 
          'טוען...'
        }
      </h2>
    </div>
      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '500px' }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable={true}
          messages={calendarMessages}
          formats={formats}
          eventPropGetter={eventStyleGetter}
          rtl={true}
          firstDayOfWeek={0}
          onRangeChange={handleRangeChange}
          onNavigate={date => setCalendarDate(date)}
          onView={view => setView(view)}
          popup
          components={{
            event: EventComponent,
            dateCellWrapper: DateCellWrapper
          }}
        />
      </div>

      {selectedPost && (
        <PostDetailsView 
          post={selectedPost}
          onClose={handleClosePostDetails}
          onApprove={handleApprovePost}
          onReject={handleRejectPost}
          onNavigate={handleNavigate}
          isFirstPost={currentPostIndex === 0}
          isLastPost={currentPostIndex === posts.length - 1}
        />
      )}
      
      <AddPostModal
        isOpen={isAddPostModalOpen}
        onClose={() => setIsAddPostModalOpen(false)}
        onSubmit={handleAddPost}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default SharedGanttView;