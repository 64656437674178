import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('התחלת תהליך התחברות');
    setError('');
    try {
      const userData = await login(email, password);
      console.log('התחברות הצליחה', userData);
      
      // ניווט בהתאם לתפקיד המשתמש
      if (userData.Role === 'admin') {
        navigate('/admin');
      } else if (userData.Role === 'user') {
        navigate('/user');
      } else {
        navigate('/'); // ברירת מחדל או דף הבית
      }
    } catch (err) {
      console.error('שגיאת התחברות:', err);
      setError('ההתחברות נכשלה. אנא בדוק את פרטי ההתחברות שלך.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="אימייל"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="סיסמה"
        required
      />
      <button type="submit">התחבר</button>
    </form>
  );
};

export default LoginComponent;