import { AIRTABLE_BASE_ID, AIRTABLE_POSTS_TABLE, getAirtableApiKey } from '../airtableConfig';

export const fetchPosts = async (ganttChartId = null) => {
  try {
    let url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_POSTS_TABLE}`;
    
    // If a Gantt Chart ID is provided, filter posts by Gantt chart
    if (ganttChartId) {
      url += `?filterByFormula=SEARCH('${ganttChartId}',{Gantt Chart})`;
    }

    console.log('Fetching posts from URL:', url);

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Raw post data:', data);
    console.log('Number of records:', data.records.length);

    // Map the response to return post objects
    const posts = data.records.map(record => ({
      id: record.id,
      title: record.fields.Title,
      description: record.fields.Description,
      scheduledDate: record.fields['Scheduled Date'],
      status: record.fields.Status,
      ganttChartId: record.fields['Gantt Chart'] ? record.fields['Gantt Chart'][0] : null,
      client: record.fields.Client ? record.fields.Client[0] : null,
      images: record.fields.Images,
      notes: record.fields.Notes,
      platform: record.fields.Platform
    }));

    console.log('Processed posts:', posts);
    return posts;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const createPost = async (postData) => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_POSTS_TABLE}`;
    
    // מנקה ומכין את התמונות לפורמט הנכון
    const images = postData.photos ? postData.photos.map(photo => ({
      url: `data:image/jpeg;base64,${photo.data}`,
      filename: `image_${Date.now()}.jpg`
    })) : [];

    console.log('Preparing images:', images);

    const formattedData = {
      fields: {
        Title: postData.title,
        Description: postData.description,
        'Scheduled Date': postData.scheduledDate.toISOString().split('T')[0],
        Status: 'הצעת הלקוח',
        Client: [postData.clientId],
        'Gantt Chart': [postData.ganttChartId],
        Notes: postData.rules || '',
        // שולח רק אם יש תמונות
        ...(images.length > 0 && { Images: images })
      }
    };

    console.log('Sending formatted data:', JSON.stringify(formattedData, null, 2));

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Airtable error details:', {
        status: response.status,
        statusText: response.statusText,
        errorData
      });
      throw new Error(errorData.error?.message || 'Failed to create post');
    }

    const data = await response.json();
    return {
      id: data.id,
      ...data.fields
    };
  } catch (error) {
    console.error('Error details:', error);
    throw error;
  }
};

// Update a post in Airtable
export const updatePostInAirtable = async (postId, updates) => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_POSTS_TABLE}/${postId}`;
    
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fields: updates })
    });

    if (!response.ok) {
      throw new Error('Failed to update post');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating post in Airtable:', error);
    throw error;
  }
};
