import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddPostModal = ({ isOpen, onClose, onSubmit, selectedDate }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageNotes, setImageNotes] = useState(''); // טקסט לגבי התמונות
  const [photos, setPhotos] = useState([]); // התמונות עצמן
  const [rules, setRules] = useState('');
  const [postDate, setPostDate] = useState(selectedDate || new Date());

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    try {
      const imageData = await Promise.all(files.map(file => 
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Data = reader.result.split(',')[1];
            resolve({
              name: file.name,
              data: base64Data,
              preview: reader.result // שומר את ה-URL המלא לתצוגה מקדימה
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
      ));
  
      console.log('Processed images:', imageData);
      setPhotos(imageData);
    } catch (error) {
      console.error('Error processing images:', error);
      alert('שגיאה בהעלאת התמונות. אנא נסה שנית.');
    }
  };
  
  // בתצוגה המקדימה:
  {photos.length > 0 && (
    <div className="image-preview">
      {photos.map((photo, index) => (
        <div key={index} className="image-preview-item">
          <img 
            src={photo.preview} 
            alt={`Preview ${index + 1}`}
          />
          <button 
            type="button" 
            onClick={() => removeImage(index)}
            className="remove-image"
          >
            ×
          </button>
        </div>
      ))}
    </div>
  )}

  const removeImage = (index) => {
    setPhotos(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit({
        title,
        description,
        photos,
        imageNotes,  // שומרים גם את הטקסט לגבי התמונות
        rules,
        scheduledDate: postDate,
        status: 'הצעת הלקוח'
      });
      onClose();
    } catch (error) {
      console.error('Error submitting post:', error);
      alert('שגיאה בשמירת הפוסט. אנא נסה שנית.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>הוסף פוסט חדש</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>כותרת הפוסט:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group">
            <label>הצעה לטקסט חי:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group images-section">
            <label>האם צריך להשתמש בתמונות מסוימות או שתרצה להוסיף תמונות להשראה:</label>
            <div className="images-input-container">
              <div className="text-area-container">
                <textarea
                  value={imageNotes}
                  onChange={(e) => setImageNotes(e.target.value)}
                  placeholder="הערות לגבי התמונות הרצויות..."
                  className="image-notes"
                />
              </div>
              
              <div className="upload-section">
                <div className="upload-button-container">
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleImageUpload}
                      style={{ display: 'none' }}
                    />
                    <span className="upload-button">העלה תמונות</span>
                  </label>
                </div>
                
                {photos.length > 0 && (
                  <div className="image-preview">
                    {photos.map((photo, index) => (
                      <div key={index} className="image-preview-item">
                        <img 
                          src={photo.data} 
                          alt={`תמונה ${index + 1}`}
                        />
                        <button 
                          type="button" 
                          onClick={() => removeImage(index)}
                          className="remove-image"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label>האם ישנן הוראות מיוחדות או דברים שחשוב לדעת?</label>
            <textarea
              value={rules}
              onChange={(e) => setRules(e.target.value)}
            />
          </div>
          
          <div className="form-group">
            <label>תאריך הפוסט:</label>
            <DatePicker
              selected={postDate}
              onChange={date => setPostDate(date)}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
            />
          </div>
          
          <div className="button-group">
            <button type="submit" className="submit-button">שמור פוסט</button>
            <button type="button" onClick={onClose} className="cancel-button">ביטול</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPostModal;