import { AIRTABLE_BASE_ID, AIRTABLE_GANTTS_TABLE, getAirtableApiKey } from '../airtableConfig';

export const fetchAllGanttCharts = async () => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_GANTTS_TABLE}`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Raw Gantt chart data:', data);
    
    return data.records.map(record => ({
      id: record.id,
      name: record.fields.Name || 'Unnamed Gantt',
      client: record.fields.Client,
      // Add other fields as needed
    }));
  } catch (error) {
    console.error('Error fetching Gantt charts:', error);
    throw error;
  }
};

export const getGanttChartsByClient = (ganttCharts, clientId) => {
  return ganttCharts.filter(gantt => 
    Array.isArray(gantt.client) && gantt.client.includes(clientId)
  );
};