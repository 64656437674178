import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginComponent from './components/LoginComponent';
import AdminPanel from './components/AdminPanel';
import UserPanel from './components/UserPanel';
import SharedGanttView from './components/SharedGanttView';
import { AuthProvider, useAuth } from './contexts/AuthContext';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" replace />;
  if (allowedRoles && !allowedRoles.includes(user.Role)) return <Navigate to="/" replace />;
  return children;
};

const PostApprovalApp = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginComponent />} />
          <Route 
            path="/admin" 
            element={
              <PrivateRoute allowedRoles={['admin']}>
                <AdminPanel />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/user" 
            element={
              <PrivateRoute allowedRoles={['user']}>
                <UserPanel />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/shared/:encodedParams" 
            element={<SharedGanttView />} 
          />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default PostApprovalApp;