import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchClients } from '../services/clientService';
import { fetchAllGanttCharts, getGanttChartsByClient } from '../services/ganttChartService';
import { fetchPosts, updatePostInAirtable } from '../services/postService';
import PostDetailsView from './PostDetailsView';
import { useAuth } from '../contexts/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const localizer = momentLocalizer(moment);

// Move getExpirationDate outside the component
const getExpirationDate = (timeLimit, customDate) => {
  if (!timeLimit) return null;
  const now = new Date();
  switch (timeLimit) {
    case 'week':
      return new Date(now.setDate(now.getDate() + 7)).toISOString();
    case 'month':
      return new Date(now.setMonth(now.getMonth() + 1)).toISOString();
    case 'custom':
      return customDate ? customDate.toISOString() : null;
    default:
      return null;
  }
};

const UserPanel = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [ganttCharts, setGanttCharts] = useState([]);
  const [selectedGantt, setSelectedGantt] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [isLastPost, setIsLastPost] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [timeLimit, setTimeLimit] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const { user } = useAuth();
  const [copied, setCopied] = useState(false);
  const [lastHandledPost, setLastHandledPost] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const [showCompletionMessage, setShowCompletionMessage] = useState(false); // New state for completion message

  const openModal = useCallback(() => {
    console.log('Opening modal');
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(async () => {
    console.log('Closing modal');
    if (lastHandledPost) {
      console.log(`Re-sending last post data for post ${lastHandledPost.id}`);
      try {
        await updatePostInAirtable(lastHandledPost.id, lastHandledPost.data);
        console.log('Last post data re-sent successfully');
      } catch (error) {
        console.error('Error re-sending last post data:', error);
        alert('Failed to update the last post. Please check and try again.');
      }
    }
    setIsModalOpen(false);
    setLastHandledPost(null);
  }, [lastHandledPost]);

  const handleNavigate = useCallback((direction) => {
    setCurrentPostIndex(prevIndex => {
      const newIndex = direction === 'next' ? prevIndex + 1 : prevIndex - 1;
      if (newIndex >= 0 && newIndex < posts.length) {
        setSelectedPost(posts[newIndex]);
        setIsLastPost(newIndex === posts.length - 1);
        return newIndex;
      }
      return prevIndex;
    });
  }, [posts]);

  const handlePostAction = useCallback(async (postId, notes, status) => {
    const isCurrentLastPost = currentPostIndex === posts.length - 1;
    const postData = { Status: status, Notes: notes };
  
    const sendPostData = async (retryAttempt = 0) => {
      try {
        await updatePostInAirtable(postId, postData);
        setPosts(prevPosts => prevPosts.map(post => 
          post.id === postId ? { ...post, status, notes } : post
        ));
        
        if (isCurrentLastPost) {
          setShowCompletionMessage(true);  // Show completion message after last post
        } else {
          handleNavigate('next');
        }
      } catch (error) {
        if (retryAttempt < MAX_RETRIES) {
          setTimeout(() => sendPostData(retryAttempt + 1), 1000 * (retryAttempt + 1));
        } else {
          alert('Failed to update the post after multiple attempts. Please try again later.');
        }
      }
    };

    await sendPostData();
  }, [currentPostIndex, posts.length, handleNavigate]);

  useEffect(() => {
    const loadClients = async () => {
      const fetchedClients = await fetchClients();
      setClients(fetchedClients);
    };
    loadClients();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      const loadGanttCharts = async () => {
        const allGanttCharts = await fetchAllGanttCharts();
        const clientGanttCharts = getGanttChartsByClient(allGanttCharts, selectedClient.id);
        setGanttCharts(clientGanttCharts);
      };
      loadGanttCharts();
    } else {
      setGanttCharts([]);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedGantt) {
      const loadPosts = async () => {
        try {
          const fetchedPosts = await fetchPosts();
          console.log('All fetched posts:', fetchedPosts);
          const ganttPosts = fetchedPosts.filter(post => post.ganttChartId === selectedGantt.id);
          console.log(`Filtered posts for Gantt chart ${selectedGantt.id}:`, ganttPosts);
          setPosts(ganttPosts);
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      };
      loadPosts();
    } else {
      setPosts([]);
    }
  }, [selectedGantt]);

  const handleSelectEvent = useCallback((event) => {
    const postIndex = posts.findIndex(p => p.id === event.id);
    setCurrentPostIndex(postIndex);
    setSelectedPost(posts[postIndex]);
  }, [posts]);

  const handleClientSelect = useCallback((clientId) => {
    const client = clients.find(c => c.id === clientId);
    setSelectedClient(client);
    setSelectedGantt(null);
    setPosts([]);
  }, [clients]);

  const handleGanttSelect = useCallback((ganttId) => {
    const gantt = ganttCharts.find(g => g.id === ganttId);
    setSelectedGantt(gantt);
  }, [ganttCharts]);

  const handleClosePostDetails = useCallback(() => {
    setSelectedPost(null);
    setCurrentPostIndex(0);
  }, []);

  const handleShare = useCallback(() => {
    if (selectedClient && selectedGantt) {
      const params = {
        client: selectedClient.id,
        gantt: selectedGantt.id,
        expires: getExpirationDate(timeLimit, customDate),
      };
      const encodedParams = btoa(JSON.stringify(params));
      const domain = window.location.origin;
      const link = `${domain}/shared/${encodedParams}`;
      setShareLink(link);
    } else {
      alert('נא לבחור לקוח וגאנט לפני יצירת קישור שיתוף');
    }
  }, [selectedClient, selectedGantt, timeLimit, customDate]);

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, []);

  const events = useMemo(() => {
    return posts.map(post => ({
      id: post.id,
      title: post.title || 'Untitled Post',
      start: new Date(post.scheduledDate),
      end: new Date(post.scheduledDate)
    }));
  }, [posts]);

  console.log('Events for calendar:', events);

  return (
    <div>
      {/* הודעת הסיום */}
      {showCompletionMessage && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          zIndex: 1000,
          textAlign: 'center'
        }}>
          <h2 style={{ color: 'green', fontWeight: 'bold' }}>
            תודה רבה! סיימת לעבור על כל הפוסטים!
          </h2>
          <button onClick={() => setShowCompletionMessage(false)}>סגור</button>
        </div>
      )}
  
      <h1>ברוך הבא, {user?.name}</h1>
      <div>
        <select 
          onChange={(e) => handleClientSelect(e.target.value)} 
          value={selectedClient?.id || ''}
        >
          <option value="">בחר לקוח</option>
          {clients.map(client => (
            <option key={client.id} value={client.id}>{client.name}</option>
          ))}
        </select>
  
        {selectedClient && (
          <select 
            onChange={(e) => handleGanttSelect(e.target.value)}
            value={selectedGantt?.id || ''}
          >
            <option value="">בחר גאנט</option>
            {ganttCharts.map(gantt => (
              <option key={gantt.id} value={gantt.id}>{gantt.name}</option>
            ))}
          </select>
        )}
  
        {selectedGantt && (
          <div>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '500px' }}
              onSelectEvent={handleSelectEvent}
            />
            <div>
              <h3>Debug Info:</h3>
              <p>Total posts: {posts.length}</p>
              <p>Total events: {events.length}</p>
              <ul>
                {events.map(event => (
                  <li key={event.id}>{event.title} - {event.start.toLocaleString()}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
  
        {selectedPost && (
          <PostDetailsView 
            post={selectedPost}
            onClose={handleClosePostDetails}
            onApprove={handlePostAction}
            onReject={(postId, notes) => handlePostAction(postId, notes, 'נדחה')}
            onNavigate={handleNavigate}
            isFirstPost={currentPostIndex === 0}
            isLastPost={isLastPost}
          />
        )}
  
        <div>
          <h3>יצירת קישור שיתוף</h3>
          <select onChange={(e) => setTimeLimit(e.target.value)}>
            <option value="">בחר מגבלת זמן</option>
            <option value="week">שבוע</option>
            <option value="month">חודש</option>
            <option value="custom">תאריך מותאם אישית</option>
          </select>
          {timeLimit === 'custom' && (
            <DatePicker 
              selected={customDate} 
              onChange={date => setCustomDate(date)} 
            />
          )}
          <button onClick={handleShare}>צור קישור שיתוף</button>
          {shareLink && (
            <div style={{ 
              marginTop: '10px', 
              padding: '10px', 
              border: '1px solid #ccc', 
              borderRadius: '5px'
            }}>
              <input 
                type="text" 
                value={shareLink} 
                readOnly 
                style={{ width: '80%', marginRight: '10px' }}
              />
              <CopyToClipboard text={shareLink} onCopy={handleCopy}>
                <button>{copied ? 'הועתק!' : 'העתק קישור'}</button>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
    </div>
  );  
}
export default UserPanel;