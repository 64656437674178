import { HebrewCalendar, Location, Event, HDate } from '@hebcal/core';

// הגדרת החודשים העבריים כקבוע פרטי
const HEBREW_MONTHS = {
    1: 'ניסן',
    2: 'אייר',
    3: 'סיוון',
    4: 'תמוז',
    5: 'אב',
    6: 'אלול',
    7: 'תשרי',
    8: 'חשוון',
    9: 'כסלו',
    10: 'טבת',
    11: 'שבט',
    12: 'אדר'
  };

// פונקציה לדיבוג תאריך עברי
const debugHebrewDate = (date) => {
    const hDate = new HDate(date);
    console.group('Hebrew Date Debug');
    console.log('Input Date:', date);
    console.log('Hebrew Date Object:', hDate);
    console.log('Month Index:', hDate.getMonth());
    console.log('Month Name:', HEBREW_MONTHS[hDate.getMonth()]);
    console.log('Day:', hDate.getDate());
    console.log('Year:', hDate.getFullYear());
    console.groupEnd();
    return hDate;
  };  

// המרת מספרים לאותיות בעברית
const numberToHebrew = (num) => {
  const letters = {
    1: 'א', 2: 'ב', 3: 'ג', 4: 'ד', 5: 'ה',
    6: 'ו', 7: 'ז', 8: 'ח', 9: 'ט', 10: 'י',
    20: 'כ', 30: 'ל', 40: 'מ', 50: 'נ',
    60: 'ס', 70: 'ע', 80: 'פ', 90: 'צ',
    100: 'ק', 200: 'ר', 300: 'ש', 400: 'ת'
  };

  if (num < 1) return '';
  
  let result = '';
  
  while (num >= 100) {
    const hundreds = Math.floor(num / 100) * 100;
    result += letters[hundreds] || '';
    num %= 100;
  }
  
  if (num >= 10) {
    const tens = Math.floor(num / 10) * 10;
    result += letters[tens] || '';
    num %= 10;
  }
  
  if (num > 0) {
    result += letters[num] || '';
  }
  
  return result;
};

// פונקציה ציבורית לקבלת שם החודש העברי
export const getHebrewMonthName = (date) => {
    const hDate = debugHebrewDate(date);
    const monthName = HEBREW_MONTHS[hDate.getMonth()];
    console.log('Returned Month Name:', monthName);
    return monthName;
  };
  
  export const formatIsraeliDate = (date) => {
    try {
      const hDate = debugHebrewDate(date);
      const day = numberToHebrew(hDate.getDate());
      const month = HEBREW_MONTHS[hDate.getMonth()];
      return `${day} ${month} תשפ"ה`;
    } catch (error) {
      console.error('Error in formatIsraeliDate:', error);
      return '';
    }
  };

// פורמט תאריך עברי מלא
export const formatFullHebrewDate = (date) => {
  try {
    const hDate = new HDate(date);
    const day = numberToHebrew(hDate.getDate());
    const month = HEBREW_MONTHS[hDate.getMonth()];
    return `${day} ${month} תשפ"ה`;
  } catch (error) {
    console.error('Error formatting Hebrew date:', error);
    return '';
  }
};

// פורמט תאריך לתא בלוח השנה
export const formatCellDate = (date) => {
  try {
    const hDate = new HDate(date);
    return {
      gregorian: date.getDate(),  // תאריך לועזי
      hebrew: numberToHebrew(hDate.getDate()),  // תאריך עברי
      month: HEBREW_MONTHS[hDate.getMonth()]  // שם החודש העברי
    };
  } catch (error) {
    console.error('Error formatting cell date:', error);
    return { gregorian: '', hebrew: '', month: '' };
  }
};

// פונקציה לקבלת החגים בטווח תאריכים
export const getHolidaysForDateRange = (startDate, endDate) => {
    try {
      console.log('Getting holidays for range:', { startDate, endDate });
      
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const years = Array.from(new Set([startYear, endYear]));
      
      let holidays = [];
      years.forEach(year => {
        const options = {
          year: year,
          isHebrewYear: false,
          location: Location.lookup('Jerusalem'),
          sedrot: false,
          candlelighting: false
        };
  
        const events = HebrewCalendar.calendar(options);
        
        events.forEach(ev => {
          // יצירת אירוע בפורמט הנכון
          const holiday = {
            title: ev.render('he'),
            titleEn: ev.render('en'),
            start: ev.getDate().greg(),
            end: new Date(ev.getDate().greg().getTime() + 24 * 60 * 60 * 1000),
            allDay: true,
            type: 'holiday',
            mask: ev.getFlags()
          };
          
          // הוספת האירוע לרשימת החגים
          holidays.push(holiday);
        });
      });
  
      // סינון החגים לפי התאריכים הרלוונטיים
      const filteredHolidays = holidays.filter(holiday => 
        holiday.start >= startDate && 
        holiday.start <= endDate
      );
      
      console.log('Final filtered holidays:', filteredHolidays);
      return filteredHolidays;
    } catch (error) {
      console.error('Error getting holidays:', error);
      return [];
    }
  };