import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchClients, fetchClientGanttCharts } from '../services/clientService';
import { fetchPosts, updatePostInAirtable } from '../services/postService';
import { fetchAllGanttCharts, getGanttChartsByClient } from '../services/ganttChartService';
import { useAuth } from '../contexts/AuthContext';
import PostDetailsView from './PostDetailsView';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = momentLocalizer(moment);

const AdminPanel = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [ganttCharts, setGanttCharts] = useState([]);
  const [selectedGanttChart, setSelectedGanttChart] = useState(null);
  const [posts, setPosts] = useState([]);
  const { user } = useAuth();
  const [selectedPost, setSelectedPost] = useState(null);
  const [shareLink, setShareLink] = useState('');
  const [timeLimit, setTimeLimit] = useState(null);
  const [customDate, setCustomDate] = useState(null);

  // State for handling fonts, colors, and logo uploads
  const [fonts, setFonts] = useState({
    primary: 'IBM Plex Sans Hebrew',
    secondary: 'IBM Plex Sans Hebrew',
    tertiary: 'IBM Plex Sans Hebrew'
  });
  const [fontWeights, setFontWeights] = useState({
    primary: '400',
    secondary: '600',
    tertiary: '200'
  });
  const [colors, setColors] = useState([]);
  const [logo, setLogo] = useState(null);

  // State for controlling UI visibility
  const [showDesignOptions, setShowDesignOptions] = useState(false);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);

  useEffect(() => {
    const loadClients = async () => {
      const fetchedClients = await fetchClients();
      console.log('Fetched clients:', fetchedClients); // For debugging
      setClients(fetchedClients);
    };
    loadClients();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      const loadGanttCharts = async () => {
        const allGanttCharts = await fetchAllGanttCharts();
        console.log('All Gantt charts:', allGanttCharts); // For debugging
        const clientGanttCharts = getGanttChartsByClient(allGanttCharts, selectedClient.id);
        console.log('Client Gantt charts:', clientGanttCharts); // For debugging
        setGanttCharts(clientGanttCharts);
      };
      loadGanttCharts();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedGanttChart) {
      const loadPosts = async () => {
        const fetchedPosts = await fetchPosts();
        // Filter posts by selected Gantt chart if needed
        const ganttPosts = fetchedPosts.filter(post => post.ganttChartId === selectedGanttChart.id);
        setPosts(ganttPosts);
      };
      loadPosts();
    }
  }, [selectedGanttChart]);

  const applyClientSettings = (settings) => {
    // Apply fonts and weights
    ['primary', 'secondary', 'tertiary'].forEach(type => {
      document.documentElement.style.setProperty(`--${type}-font`, settings[`${type}Font`] || 'IBM Plex Sans Hebrew');
      document.documentElement.style.setProperty(`--${type}-font-weight`, settings[`${type}FontWeight`] || (type === 'secondary' ? '600' : type === 'tertiary' ? '200' : '400'));
    });
    
    // Apply colors
    settings.colors.forEach((color, index) => {
      document.documentElement.style.setProperty(`--color-${index + 1}`, color);
    });

    // Apply logo (if applicable)
    if (settings.logo) {
      // TODO: Update logo in UI (e.g., set src of an img element)
      console.log('Applying logo:', settings.logo);
    }
  };

  const handleFontChange = (fontType, fontFamily) => {
    setFonts(prevFonts => ({
      ...prevFonts,
      [fontType]: fontFamily
    }));

    if (selectedClient) {
      // TODO: Implement actual client settings update
      console.log(`Updating ${fontType} font to ${fontFamily} for client ${selectedClient.id}`);
      document.documentElement.style.setProperty(`--${fontType}-font`, fontFamily);
    }
  };

  const loadClientFonts = (clientId) => {
    // TODO: Implement actual client settings fetch
    console.log(`Loading fonts for client ${clientId}`);
    const defaultFonts = {
      primary: 'IBM Plex Sans Hebrew',
      secondary: 'IBM Plex Sans Hebrew',
      tertiary: 'IBM Plex Sans Hebrew'
    };
    setFonts(defaultFonts);
    Object.entries(defaultFonts).forEach(([type, font]) => {
      document.documentElement.style.setProperty(`--${type}-font`, font);
    });
  };

  const handleClientSelect = (clientId) => {
    const selected = clients.find(client => client.id === clientId);
    console.log('Selected client:', selected); // For debugging
    setSelectedClient(selected);
    setSelectedGanttChart(null); // Reset Gantt chart when changing client
    loadClientFonts(clientId);
  };

   const handleFontWeightChange = (fontType, weight) => {
    setFontWeights(prevWeights => ({
      ...prevWeights,
      [fontType]: weight
    }));

    if (selectedClient) {
      // TODO: Implement actual client settings update
      console.log(`Updating ${fontType} font weight to ${weight} for client ${selectedClient.id}`);
      document.documentElement.style.setProperty(`--${fontType}-font-weight`, weight);
    }
  };

  const loadClientSettings = (clientId) => {
    // TODO: Implement actual client settings fetch
    console.log(`Loading settings for client ${clientId}`);
    const defaultSettings = {
      primaryFont: 'IBM Plex Sans Hebrew',
      secondaryFont: 'IBM Plex Sans Hebrew',
      tertiaryFont: 'IBM Plex Sans Hebrew',
      primaryFontWeight: '400',
      secondaryFontWeight: '600',
      tertiaryFontWeight: '200',
      colors: [],
      logo: null
    };
    setFonts({
      primary: defaultSettings.primaryFont,
      secondary: defaultSettings.secondaryFont,
      tertiary: defaultSettings.tertiaryFont
    });
    setFontWeights({
      primary: defaultSettings.primaryFontWeight,
      secondary: defaultSettings.secondaryFontWeight,
      tertiary: defaultSettings.tertiaryFontWeight
    });
    setColors(defaultSettings.colors);
    setLogo(defaultSettings.logo);
    applyClientSettings(defaultSettings);
  };

  const handleFontUpload = (event) => {
    const files = Array.from(event.target.files);
    const newFonts = files.map(file => file.name);
    setFonts(prevFonts => ({
      ...prevFonts,
      uploaded: [...(prevFonts.uploaded || []), ...newFonts]
    }));
    
    if (selectedClient) {
      console.log(`Uploading fonts for client ${selectedClient.id}:`, newFonts);
      // TODO: Implement actual font upload and client settings update
    }
  };

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setColors(prevColors => [...prevColors, newColor]);
    
    if (selectedClient) {
      // TODO: Implement actual client settings update
      console.log(`Adding color ${newColor} for client ${selectedClient.id}`);
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    setLogo(file.name);
    
    if (selectedClient) {
      // TODO: Implement actual client settings update
      console.log(`Uploading logo ${file.name} for client ${selectedClient.id}`);
    }
  };

  const handleGanttChartSelect = (ganttChartId) => {
    const selected = ganttCharts.find(gantt => gantt.id === ganttChartId);
    console.log('Selected Gantt chart:', selected); // For debugging
    setSelectedGanttChart(selected);
  };

  const handleSelectEvent = (event) => {
    const postIndex = posts.findIndex(p => p.id === event.id);
    setCurrentPostIndex(postIndex);
    setSelectedPost(posts[postIndex]);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  const handleApprovePost = async (postId, notes, status) => {
    try {
      await updatePostInAirtable(postId, { Status: status, Notes: notes });
      setPosts(posts.map(post => 
        post.id === postId ? { ...post, status, notes } : post
      ));
      navigateToNextPost();
    } catch (error) {
      console.error('Error approving post:', error);
    }
  };

  const handleRejectPost = async (postId, notes) => {
    try {
      await updatePostInAirtable(postId, { Status: 'נדחה', Notes: notes });
      setPosts(posts.map(post => 
        post.id === postId ? { ...post, status: 'נדחה', notes } : post
      ));
      navigateToNextPost();
    } catch (error) {
      console.error('Error rejecting post:', error);
    }
  };

  const navigateToNextPost = () => {
    if (currentPostIndex < posts.length - 1) {
      setCurrentPostIndex(currentPostIndex + 1);
      setSelectedPost(posts[currentPostIndex + 1]);
    } else {
      // סנכרון הפוסט האחרון לפני סיום
      if (selectedPost) {
        handleApprovePost(selectedPost.id, selectedPost.notes, selectedPost.status);
      }
      setSelectedPost(null); // מציג הודעה "הידד"
    }
  };
  

  const handleNavigate = (direction) => {
    const newIndex = direction === 'next' ? currentPostIndex + 1 : currentPostIndex - 1;
    if (newIndex >= 0 && newIndex < posts.length) {
      setCurrentPostIndex(newIndex);
      setSelectedPost(posts[newIndex]);
    }
  };

  // Convert posts to calendar events
  const events = posts.map(post => ({
    id: post.id,
    title: post.title || 'Untitled Post',
    start: new Date(post.scheduledDate),
    end: new Date(post.scheduledDate)
  }));

  const handleShare = () => {
    if (selectedClient && selectedGanttChart) {
      const params = {
        client: selectedClient.id,
        gantt: selectedGanttChart.id,
        expires: getExpirationDate(),
      };
      const encodedParams = btoa(JSON.stringify(params));
      const domain = window.location.origin;
      const link = `${domain}/shared/${encodedParams}`;
      setShareLink(link);
      console.log('Generated share link:', link); // For debugging
    } else {
      alert('נא לבחור לקוח וגאנט לפני יצירת קישור שיתוף');
    }
  };

  const getExpirationDate = () => {
    if (!timeLimit) return null;
    const now = new Date();
    switch (timeLimit) {
      case 'week':
        return new Date(now.setDate(now.getDate() + 7)).toISOString();
      case 'month':
        return new Date(now.setMonth(now.getMonth() + 1)).toISOString();
      case 'custom':
        return customDate ? customDate.toISOString() : null;
      default:
        return null;
    }
  };

  return (
    <div>
      <h1>ברוך הבא, {user?.name}</h1>
      <h2>פאנל ניהול</h2>
      
      {/* Client Selection */}
      <select onChange={(e) => handleClientSelect(e.target.value)} value={selectedClient?.id || ''}>
        <option value="">בחר לקוח</option>
        {clients.map(client => (
          <option key={client.id} value={client.id}>{client.name}</option>
        ))}
      </select>

      {selectedClient && (
        <div>
          <h3>הגדרות פונט</h3>
          {['primary', 'secondary', 'tertiary'].map(type => (
            <div key={type} style={{ marginBottom: '10px' }}>
              <label style={{ marginRight: '10px' }}>פונט {type}:</label>
              <select
                value={fonts[type]}
                onChange={(e) => handleFontChange(type, e.target.value)}
                style={{ marginRight: '10px' }}
              >
                <option value="IBM Plex Sans Hebrew">IBM Plex Sans Hebrew</option>
                <option value="Arial">Arial</option>
                <option value="Helvetica">Helvetica</option>
                {fonts.uploaded && fonts.uploaded.map(font => (
                  <option key={font} value={font}>{font}</option>
                ))}
              </select>
              <select
                value={fontWeights[type]}
                onChange={(e) => handleFontWeightChange(type, e.target.value)}
              >
                <option value="200">ExtraLight</option>
                <option value="400">Regular</option>
                <option value="600">SemiBold</option>
              </select>
            </div>
          ))}
          <div>
            <h4>הוסף פונטים חדשים</h4>
            <input 
              type="file" 
              accept=".ttf,.otf,.woff,.woff2" 
              onChange={handleFontUpload} 
              multiple 
            />
          </div>

          {/* Other design options */}
          <button onClick={() => setShowDesignOptions(!showDesignOptions)}>
            אפשרויות עיצוב נוספות
          </button>
          
          {showDesignOptions && (
            <div>
              <h4>צבעים</h4>
              {colors.map((color, index) => (
                <input
                  key={index}
                  type="color"
                  value={color}
                  onChange={(e) => handleColorChange(e, index)}
                />
              ))}
              <button onClick={() => setColors([...colors, '#000000'])}>Add Color</button>

              <h4>לוגו</h4>
              <input type="file" accept="image/*" onChange={handleLogoUpload} />
              {logo && <img src={logo} alt="Client Logo" style={{ maxWidth: '200px' }} />}
            </div>
          )}

          {/* Gantt Chart Selection */}
          <select 
            onChange={(e) => handleGanttChartSelect(e.target.value)}
            value={selectedGanttChart?.id || ''}
          >
            <option value="">בחר גאנט</option>
            {ganttCharts.map(gantt => (
              <option key={gantt.id} value={gantt.id}>{gantt.name}</option>
            ))}
          </select>

          
          {/* Calendar View */}
          {selectedGanttChart && (
        <div>
          <h3>לוח שנה עבור {selectedGanttChart.name}</h3>
          
          <div>
            <label>
              <input
                type="checkbox"
                checked={!!timeLimit}
                onChange={(e) => setTimeLimit(e.target.checked ? 'week' : null)}
              />
              קישור מוגבל בזמן
            </label>
            {timeLimit && (
              <select value={timeLimit} onChange={(e) => setTimeLimit(e.target.value)}>
                <option value="week">שבוע</option>
                <option value="month">חודש</option>
                <option value="custom">תאריך מותאם אישית</option>
              </select>
            )}
            {timeLimit === 'custom' && (
              <DatePicker
                selected={customDate}
                onChange={date => setCustomDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            )}
          </div>
          
          <button onClick={handleShare}>שתף גאנט</button>
          
          {shareLink && (
            <div>
              <p>קישור לשיתוף:</p>
              <input type="text" value={shareLink} readOnly />
              <button onClick={() => navigator.clipboard.writeText(shareLink)}>
                העתק קישור
              </button>
            </div>
          )}
              
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '500px' }}
                onSelectEvent={handleSelectEvent}
              />
            </div>
          )}

          {/* PostDetailsView */}
          {selectedPost && (
            <PostDetailsView 
              post={selectedPost} 
              onClose={handleCloseModal}
              onApprove={handleApprovePost}
              onReject={handleRejectPost}
              onNavigate={handleNavigate}
              isFirstPost={currentPostIndex === 0}
              isLastPost={currentPostIndex === posts.length - 1}
            />
          )}

           {/* Domain and Airtable configuration */}
           <div style={{ color: 'gray' }}>
            <h3>התחברות לדומיין</h3>
            <p>(כאן תהיה אפשרות לחבר דומיין עם רשומות A או MX)</p>

            <h3>קונפיגורציה של Airtable</h3>
            <p>(כאן יהיה מקום להזין Token ושמות של טבלאות)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;