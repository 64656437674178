import { AIRTABLE_BASE_ID, AIRTABLE_USERS_TABLE, getAirtableApiKey } from '../airtableConfig';

export const login = async (email, password) => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_USERS_TABLE}?filterByFormula=AND({Email}='${email}',{Password}='${password}')`;
    const response = await fetch(url, {
      headers: { 
        'Authorization': `Bearer ${getAirtableApiKey()}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to authenticate');
    }

    const data = await response.json();
    if (data.records.length === 0) {
      throw new Error('Invalid credentials');
    }

    const user = data.records[0].fields;
    return { id: data.records[0].id, ...user };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('user');
};