import React, { useState, useEffect } from 'react';

const PostDetailsView = ({ post, onClose, onApprove, onReject, onNavigate, isFirstPost, isLastPost }) => {
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Clear notes when a new post is loaded
    setNotes('');
    setError('');
  }, [post]);

  const handleApproveWithoutNotes = () => {
    onApprove(post.id, '', 'מאושר');
  };

  const handleApproveWithNotes = () => {
    if (notes.trim() === '') {
      setError('יש להזין הערות לפני אישור עם הערות');
      return;
    }
    onApprove(post.id, notes, 'הערות');
  };

  const handleReject = () => {
    if (notes.trim() === '') {
      setError('יש למלא את שדה ההערות לפני דחייה');
      return;
    }
    onReject(post.id, notes);
  };

  if (!post) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      zIndex: 1000
    }}>
      <h2>{post.title}</h2>
      {post.images && post.images.length > 0 && (
        <img src={post.images[0].url} alt={post.title} style={{ maxWidth: '100%', maxHeight: '300px' }} />
      )}
      <p>{post.description}</p>
      <small style={{ color: 'gray' }}>הערה: הפוסט מוצג עם הפונטים הנכונים כפי שהוגדרו עבור הלקוח</small>      
      <textarea
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
          setError('');
        }}
        placeholder="הוסף הערות כאן"
        style={{ width: '100%', minHeight: '100px', marginBottom: '10px' }}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        <button onClick={handleApproveWithoutNotes} style={{ backgroundColor: 'green', color: 'white' }}>
          אשר ללא הערות
        </button>
        <button onClick={handleApproveWithNotes} style={{ backgroundColor: 'blue', color: 'white' }}>
          אשר עם הערות
        </button>
        <button onClick={handleReject} style={{ backgroundColor: 'red', color: 'white' }}>
          דחה
        </button>
        <button onClick={onClose}>סגור</button>
      </div>
      <div style={{ marginTop: '10px' }}>
        <button onClick={() => onNavigate('prev')} disabled={isFirstPost}>
          &larr; הקודם
        </button>
        <button onClick={() => onNavigate('next')} disabled={isLastPost}>
          הבא &rarr;
        </button>
      </div>
    </div>
  );
};

export default PostDetailsView;
