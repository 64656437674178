import { AIRTABLE_BASE_ID, AIRTABLE_CLIENTS_TABLE, getAirtableApiKey } from '../airtableConfig';

export const fetchClients = async () => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_CLIENTS_TABLE}`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Raw client data:', data);
    
    return data.records.map(record => ({
      id: record.id,
      name: record.fields['Client Name'],
      ganttCharts: record.fields['Gantt Charts'] || []
    }));
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

export const fetchClientGanttCharts = async (clientId) => {
  try {
    const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_CLIENTS_TABLE}/${clientId}?fields[]=Gantt%20Charts`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getAirtableApiKey()}`,
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Raw client Gantt charts data:', data);
    
    return data.fields['Gantt Charts'] || [];
  } catch (error) {
    console.error('Error fetching client Gantt charts:', error);
    throw error;
  }
};